exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-datenschutz-messe-tsx": () => import("./../../../src/pages/datenschutz-messe.tsx" /* webpackChunkName: "component---src-pages-datenschutz-messe-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-datenschutz-webinar-tsx": () => import("./../../../src/pages/datenschutz-webinar.tsx" /* webpackChunkName: "component---src-pages-datenschutz-webinar-tsx" */),
  "component---src-pages-duckeneers-tsx": () => import("./../../../src/pages/duckeneers.tsx" /* webpackChunkName: "component---src-pages-duckeneers-tsx" */),
  "component---src-pages-e-rechnung-tsx": () => import("./../../../src/pages/e-rechnung.tsx" /* webpackChunkName: "component---src-pages-e-rechnung-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-karriere-tsx": () => import("./../../../src/pages/karriere.tsx" /* webpackChunkName: "component---src-pages-karriere-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kunden-feedback-tsx": () => import("./../../../src/pages/kunden-feedback.tsx" /* webpackChunkName: "component---src-pages-kunden-feedback-tsx" */),
  "component---src-pages-kundenzufriedenheit-tsx": () => import("./../../../src/pages/kundenzufriedenheit.tsx" /* webpackChunkName: "component---src-pages-kundenzufriedenheit-tsx" */),
  "component---src-pages-leistungen-individuelle-softwareentwicklung-automotive-tsx": () => import("./../../../src/pages/leistungen/individuelle-softwareentwicklung/automotive.tsx" /* webpackChunkName: "component---src-pages-leistungen-individuelle-softwareentwicklung-automotive-tsx" */),
  "component---src-pages-leistungen-individuelle-softwareentwicklung-hubspot-dashboards-tsx": () => import("./../../../src/pages/leistungen/individuelle-softwareentwicklung/hubspot-dashboards.tsx" /* webpackChunkName: "component---src-pages-leistungen-individuelle-softwareentwicklung-hubspot-dashboards-tsx" */),
  "component---src-pages-leistungen-individuelle-softwareentwicklung-pharma-tsx": () => import("./../../../src/pages/leistungen/individuelle-softwareentwicklung/pharma.tsx" /* webpackChunkName: "component---src-pages-leistungen-individuelle-softwareentwicklung-pharma-tsx" */),
  "component---src-pages-leistungen-individuelle-softwareentwicklung-tsx": () => import("./../../../src/pages/leistungen/individuelle-softwareentwicklung.tsx" /* webpackChunkName: "component---src-pages-leistungen-individuelle-softwareentwicklung-tsx" */),
  "component---src-pages-leistungen-individuelle-web-app-entwicklung-tsx": () => import("./../../../src/pages/leistungen/individuelle-web-app-entwicklung.tsx" /* webpackChunkName: "component---src-pages-leistungen-individuelle-web-app-entwicklung-tsx" */),
  "component---src-pages-leistungen-tsx": () => import("./../../../src/pages/leistungen.tsx" /* webpackChunkName: "component---src-pages-leistungen-tsx" */),
  "component---src-pages-projekte-tsx": () => import("./../../../src/pages/projekte.tsx" /* webpackChunkName: "component---src-pages-projekte-tsx" */),
  "component---src-pages-projektinfo-tsx": () => import("./../../../src/pages/projektinfo.tsx" /* webpackChunkName: "component---src-pages-projektinfo-tsx" */),
  "component---src-pages-unternehmen-tsx": () => import("./../../../src/pages/unternehmen.tsx" /* webpackChunkName: "component---src-pages-unternehmen-tsx" */),
  "component---src-pages-zeiterfassung-tsx": () => import("./../../../src/pages/zeiterfassung.tsx" /* webpackChunkName: "component---src-pages-zeiterfassung-tsx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-10-nuetzliche-ki-tools-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/10-nuetzliche-ki-tools.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-10-nuetzliche-ki-tools-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-10-vorteile-individualsoftware-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/10-vorteile-individualsoftware.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-10-vorteile-individualsoftware-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-3-beispiele-individualsoftware-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/3-beispiele-individualsoftware.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-3-beispiele-individualsoftware-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-5-trends-digitalisierung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/5-trends-digitalisierung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-5-trends-digitalisierung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-80000-euro-sparen-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/80000-euro-sparen.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-80000-euro-sparen-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-access-abloesen-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/access-abloesen.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-access-abloesen-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-agile-softwareentwicklung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/agile-softwareentwicklung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-agile-softwareentwicklung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-api-integration-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/api-integration.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-api-integration-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-api-schnittstellen-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/api-schnittstellen.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-api-schnittstellen-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-app-development-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/app-development.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-app-development-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-asset-tracking-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/asset-tracking.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-asset-tracking-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-augmented-reality-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/augmented-reality.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-augmented-reality-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-ausbildung-2023-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/ausbildung-2023.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-ausbildung-2023-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-automatische-datenerfassung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/automatische-datenerfassung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-automatische-datenerfassung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-automatisierte-tests-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/automatisierte-tests.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-automatisierte-tests-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-barrierefreie-websites-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/barrierefreie-websites.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-barrierefreie-websites-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-blockchain-fuer-den-mittelstand-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/blockchain-fuer-den-mittelstand.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-blockchain-fuer-den-mittelstand-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-browser-extensions-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/browser-extensions.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-browser-extensions-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-cloud-computing-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/cloud-computing.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-cloud-computing-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-cyber-security-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/cyber-security.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-cyber-security-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-daten-mitnahme-teil-1-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/daten-mitnahme-teil-1.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-daten-mitnahme-teil-1-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-daten-mitnahme-teil-2-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/daten-mitnahme-teil-2.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-daten-mitnahme-teil-2-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-datenanalyse-bi-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/datenanalyse-bi.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-datenanalyse-bi-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-devduck-gamp-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/devduck-gamp.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-devduck-gamp-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-devops-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/devops.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-devops-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-digitale-strategie-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/digitale-strategie.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-digitale-strategie-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-digitaler-arbeitsplatz-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/digitaler-arbeitsplatz.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-digitaler-arbeitsplatz-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-digitalisierung-industrie-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/digitalisierung-industrie.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-digitalisierung-industrie-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-dokumentenmanagement-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/dokumentenmanagement.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-dokumentenmanagement-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-e-rechnung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/e-rechnung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-e-rechnung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-edge-computing-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/edge-computing.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-edge-computing-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-energiemanagement-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/energiemanagement.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-energiemanagement-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-erp-systeme-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/erp-systeme.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-erp-systeme-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-excel-tabellen-abloesen-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/excel-tabellen-abloesen.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-excel-tabellen-abloesen-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-fahrerlose-transportsysteme-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/fahrerlose-transportsysteme.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-fahrerlose-transportsysteme-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-foerdermittel-digitalisierung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/foerdermittel-digitalisierung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-foerdermittel-digitalisierung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-gamification-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/gamification.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-gamification-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-german-web-award-23-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/german-web-award-23.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-german-web-award-23-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-google-cloud-partner-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/google-cloud-partner.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-google-cloud-partner-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-herausforderungen-digitalisierung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/herausforderungen-digitalisierung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-herausforderungen-digitalisierung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-hubspot-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/hubspot.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-hubspot-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-individualsoftware-entwicklung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/individualsoftware-entwicklung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-individualsoftware-entwicklung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-individualsoftware-kosten-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/individualsoftware-kosten.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-individualsoftware-kosten-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-industrie-40-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/industrie-40.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-industrie-40-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-industrie-50-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/industrie-50.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-industrie-50-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-inselwissen-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/inselwissen.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-inselwissen-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-itcs-2022-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/itcs-2022.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-itcs-2022-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-karrieretag-ka-2023-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/karrieretag-ka-2023.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-karrieretag-ka-2023-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-karrieretag-stuttgart-2023-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/karrieretag-stuttgart-2023.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-karrieretag-stuttgart-2023-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-ki-ml-in-der-industrie-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/ki-ml-in-der-industrie.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-ki-ml-in-der-industrie-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-kundenservice-prozessoptimierung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/kundenservice-prozessoptimierung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-kundenservice-prozessoptimierung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-labelerkennung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/labelerkennung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-labelerkennung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-low-code-no-code-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/low-code-no-code.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-low-code-no-code-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-machine-learning-prozessoptimierung-in-der-logistik-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/machine-learning-prozessoptimierung-in-der-logistik.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-machine-learning-prozessoptimierung-in-der-logistik-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-make-or-buy-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/make-or-buy.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-make-or-buy-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-menschen-und-ki-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/menschen-und-ki.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-menschen-und-ki-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-mobile-apps-in-der-industrie-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/mobile-apps-in-der-industrie.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-mobile-apps-in-der-industrie-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-modularer-aufbau-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/modularer-aufbau.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-modularer-aufbau-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-motek-2023-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/motek-2023.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-motek-2023-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-nachhaltige-softwareentwicklung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/nachhaltige-softwareentwicklung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-nachhaltige-softwareentwicklung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-nfc-technologie-in-der-industrie-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/nfc-technologie-in-der-industrie.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-nfc-technologie-in-der-industrie-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-openai-news-november-23-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/openai-news-november-23.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-openai-news-november-23-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-plattformubergreifende-entwicklung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/plattformubergreifende-entwicklung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-plattformubergreifende-entwicklung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-predictive-maintenance-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/predictive-maintenance.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-predictive-maintenance-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-progressive-web-apps-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/progressive-web-apps.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-progressive-web-apps-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-prozess-optimierung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/prozess-optimierung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-prozess-optimierung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-prozessautomatisierung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/prozessautomatisierung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-prozessautomatisierung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-quality-management-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/quality-management.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-quality-management-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-rubber-duck-debugging-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/rubber-duck-debugging.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-rubber-duck-debugging-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-shopfloor-boards-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/shopfloor-boards.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-shopfloor-boards-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-sichere-softwareentwicklung-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/sichere-softwareentwicklung.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-sichere-softwareentwicklung-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-smart-factory-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/smart-factory.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-smart-factory-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-standard-vs-individual-software-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/standard-vs-individual-software.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-standard-vs-individual-software-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-usecase-datenmitnahme-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/usecase-datenmitnahme.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-usecase-datenmitnahme-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-usecase-protokolle-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/usecase-protokolle.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-usecase-protokolle-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-usecase-rolltore-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/usecase-rolltore.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-usecase-rolltore-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-ux-wettbewerbsvorteil-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/ux-wettbewerbsvorteil.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-ux-wettbewerbsvorteil-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-viele-programme-verursachen-fehler-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/viele-programme-verursachen-fehler.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-viele-programme-verursachen-fehler-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-vorteile-webanwendungen-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/vorteile-webanwendungen.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-vorteile-webanwendungen-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-weihnachtsfeier-2022-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/weihnachtsfeier-2022.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-weihnachtsfeier-2022-mdx" */),
  "component---src-templates-article-template-tsx-content-file-path-src-content-articles-wwdc-2023-mdx": () => import("./../../../src/templates/ArticleTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/articles/wwdc-2023.mdx" /* webpackChunkName: "component---src-templates-article-template-tsx-content-file-path-src-content-articles-wwdc-2023-mdx" */),
  "component---src-templates-branch-template-tsx-content-file-path-src-content-industries-automotive-md": () => import("./../../../src/templates/BranchTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/industries/automotive.md" /* webpackChunkName: "component---src-templates-branch-template-tsx-content-file-path-src-content-industries-automotive-md" */),
  "component---src-templates-branch-template-tsx-content-file-path-src-content-industries-fertigung-md": () => import("./../../../src/templates/BranchTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/industries/fertigung.md" /* webpackChunkName: "component---src-templates-branch-template-tsx-content-file-path-src-content-industries-fertigung-md" */),
  "component---src-templates-branch-template-tsx-content-file-path-src-content-industries-marketing-md": () => import("./../../../src/templates/BranchTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/industries/marketing.md" /* webpackChunkName: "component---src-templates-branch-template-tsx-content-file-path-src-content-industries-marketing-md" */),
  "component---src-templates-branch-template-tsx-content-file-path-src-content-industries-pharma-md": () => import("./../../../src/templates/BranchTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/industries/pharma.md" /* webpackChunkName: "component---src-templates-branch-template-tsx-content-file-path-src-content-industries-pharma-md" */),
  "component---src-templates-branch-template-tsx-content-file-path-src-content-industries-startup-md": () => import("./../../../src/templates/BranchTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/industries/startup.md" /* webpackChunkName: "component---src-templates-branch-template-tsx-content-file-path-src-content-industries-startup-md" */),
  "component---src-templates-branch-template-tsx-content-file-path-src-content-industries-vertrieb-md": () => import("./../../../src/templates/BranchTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/industries/vertrieb.md" /* webpackChunkName: "component---src-templates-branch-template-tsx-content-file-path-src-content-industries-vertrieb-md" */),
  "component---src-templates-job-template-tsx-content-file-path-src-content-jobs-sw-engineer-md": () => import("./../../../src/templates/JobTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/jobs/sw-engineer.md" /* webpackChunkName: "component---src-templates-job-template-tsx-content-file-path-src-content-jobs-sw-engineer-md" */),
  "component---src-templates-job-template-tsx-content-file-path-src-content-jobs-sw-engineer-study-md": () => import("./../../../src/templates/JobTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/jobs/sw-engineer-study.md" /* webpackChunkName: "component---src-templates-job-template-tsx-content-file-path-src-content-jobs-sw-engineer-study-md" */),
  "component---src-templates-job-template-tsx-content-file-path-src-content-jobs-ts-engineer-md": () => import("./../../../src/templates/JobTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/jobs/ts-engineer.md" /* webpackChunkName: "component---src-templates-job-template-tsx-content-file-path-src-content-jobs-ts-engineer-md" */),
  "component---src-templates-project-template-tsx-content-file-path-src-content-projects-agrichema-md": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/projects/agrichema.md" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-content-projects-agrichema-md" */),
  "component---src-templates-project-template-tsx-content-file-path-src-content-projects-creavac-md": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/projects/creavac.md" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-content-projects-creavac-md" */),
  "component---src-templates-project-template-tsx-content-file-path-src-content-projects-kohlpharma-md": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/projects/kohlpharma.md" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-content-projects-kohlpharma-md" */),
  "component---src-templates-project-template-tsx-content-file-path-src-content-projects-promodx-md": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/projects/promodx.md" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-content-projects-promodx-md" */),
  "component---src-templates-project-template-tsx-content-file-path-src-content-projects-promodx-pwa-md": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/projects/promodx_pwa.md" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-content-projects-promodx-pwa-md" */),
  "component---src-templates-project-template-tsx-content-file-path-src-content-projects-samobile-md": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/projects/samobile.md" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-content-projects-samobile-md" */),
  "component---src-templates-project-template-tsx-content-file-path-src-content-projects-schaeflein-covid-md": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/projects/schaeflein_covid.md" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-content-projects-schaeflein-covid-md" */),
  "component---src-templates-project-template-tsx-content-file-path-src-content-projects-schaeflein-pallet-scan-md": () => import("./../../../src/templates/ProjectTemplate.tsx?__contentFilePath=/Users/robert/Documents/git/group-landingpage/src/content/projects/schaeflein-pallet-scan.md" /* webpackChunkName: "component---src-templates-project-template-tsx-content-file-path-src-content-projects-schaeflein-pallet-scan-md" */)
}

